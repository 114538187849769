import {
  Button,
  Center,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaSpotify } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ListItem } from "src/types/list";

const ItemModal: React.FC<{
  item: ListItem;
  disclosure: UseDisclosureReturn;
  tall?: boolean;
}> = ({ item, disclosure, tall }) => {
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
      <ModalOverlay onClick={disclosure.onClose} backdropFilter="blur(8px)" />
      <ModalContent
        bg="transparent"
        boxShadow="none"
        borderRadius="none"
        px="1em"
      >
        <ModalBody>
          <Center h="100%">
            <VStack>
              <Image
                src={item.imageURI}
                alt={item.title}
                h="12em"
                maxW={"12em"}
                objectFit="cover"
                boxShadow="md"
                background="white"
                borderRadius={
                  localStorage.getItem("borderRadiusDisabled") === "true"
                    ? undefined
                    : "4px"
                }
                mb="0.5em"
              />
              <Heading color="white" fontSize="1.4em" textAlign="center">
                {item.title}
              </Heading>
              {item.subtitle && (
                <Heading
                  color="topFive.gray.light"
                  fontSize="1em"
                  textAlign="center"
                >
                  {item.subtitle}
                </Heading>
              )}
              {item.link && (
                <Button
                  as={Link}
                  to={item.link}
                  leftIcon={<Icon as={FaSpotify} />}
                  mt="5px"
                >
                  Open in Spotify
                </Button>
              )}
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ItemModal;
