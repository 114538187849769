import {
  Button,
  Heading,
  HStack,
  Icon,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaList, FaUsers } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "src/components/Buttons";
import { PodiumsWrap } from "src/components/PodiumsCard";
import { UserRows } from "src/components/User/ListOfUsers";
import {
  useCurrentUser,
  useGetAllUsers,
  useGetWaitlist,
} from "src/state/userQueries";
export enum SelectedPage {
  SelectPage = "SelectPage",
  Waitlist = "Waitlist",
  Users = "Users",
}
const AdminPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);
  return (
    <PodiumsWrap>
      <VStack w="100%">
        <HStack width="100%">
          <BackButton to="/me" width="auto" />
          <Heading width="100%" textAlign="center">
            Admin
          </Heading>
        </HStack>
        <Button as={Link} to="/admin/waitlist" width="100%" size="lg">
          <Icon as={FaList} mr="0.5em" />
          View Waitlist
        </Button>
        <Button as={Link} to="/admin/users" width="100%" size="lg">
          <Icon as={FaUsers} mr="0.5em" />
          View Users
        </Button>
      </VStack>
    </PodiumsWrap>
  );
};

export const AdminWaitlistPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();
  const userQuery = useGetWaitlist();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);

  const Inner = () => {
    if (currentUserQuery.isLoading || currentUserQuery.isError) {
      return <Heading>Loading...</Heading>;
    }
    return (
      <VStack width="100%">
        <HStack width="100%">
          <BackButton to="/admin" width="auto" />
          <Heading width="100%" textAlign="center">
            Waitlist ({userQuery.data?.length ?? "..."})
          </Heading>
        </HStack>
        <UserRows
          isLoading={userQuery.isLoading}
          isError={userQuery.isError}
          users={userQuery.data}
          navigate={navigate}
          isAdmin
        />
      </VStack>
    );
  };
  return (
    <PodiumsWrap>
      <Inner />
    </PodiumsWrap>
  );
};

export const AdminUserPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();
  const userQuery = useGetAllUsers();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);

  const Inner = () => {
    if (currentUserQuery.isLoading || currentUserQuery.isError) {
      return <Heading>Loading...</Heading>;
    }
    return (
      <VStack width="100%">
        <HStack width="100%">
          <BackButton to="/admin" width="auto" />
          <Heading width="100%" textAlign="center">
            Users ({userQuery.data?.length ?? "..."})
          </Heading>
        </HStack>
        <UserRows
          isLoading={userQuery.isLoading}
          isError={userQuery.isError}
          users={userQuery.data}
          navigate={navigate}
          isAdmin
        />
      </VStack>
    );
  };
  return (
    <PodiumsWrap px={4}>
      <Inner />
    </PodiumsWrap>
  );
};

export default AdminPage;
