import { Flex, Grid, GridItem, Image, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React from "react";
import { ListItem } from "src/types/list";
import TruncatedTitle from "../Utils/TruncatedTitle";
import ItemModal from "./ItemModal";

const ListGridItem: React.FC<{
  item: ListItem;
  rank: number;
  imageHeight?: any;
  bgColor?: string;
  borderRadius?: boolean;
  isDragging?: boolean;
  imagePadding?: string | number;
  enableDetailModal?: boolean;
}> = ({
  item,
  rank,
  imageHeight,
  bgColor,
  borderRadius,
  isDragging,
  imagePadding,
  enableDetailModal,
}) => {
  const disclosure = useDisclosure();
  const itemTitlesEnabled = useFeatureFlagEnabled("itemTitles");
  return (
    <GridItem
      width="100%"
      minWidth="0"
      pos="relative"
      onClick={enableDetailModal ? disclosure.onOpen : () => {}}
      cursor={enableDetailModal ? "pointer" : "auto"}
    >
      <Flex gap="0.5em" align="center" direction="column-reverse">
        {itemTitlesEnabled && (
          <TruncatedTitle
            bgColor={bgColor}
            title={item.title}
            rank={rank}
            subtitle={item.subtitle}
          />
        )}
        <motion.div
          layoutId={`image-${item._id}`}
          transition={isDragging ? { duration: 0 } : { duration: 0.3 }}
        >
          <Image
            src={item.imageURI}
            alt={item.title}
            objectFit="cover"
            w="100%"
            minW="100%"
            h={imageHeight ?? "5.5em"}
            padding={imagePadding}
            borderRadius={borderRadius ? "4px" : undefined}
            // boxShadow="md"
          />
        </motion.div>
      </Flex>
      <ItemModal item={item} disclosure={disclosure} />
    </GridItem>
  );
};

const ListGrid: React.FC<{
  items: ListItem[];
  tallImages?: boolean;
  imageHeight?: any;
  columns?: number;
  bgColor?: string;
  borderRadius?: boolean;
  isDragging?: boolean;
  imagePadding?: string | number;
  enableDetailModal?: boolean;
}> = ({
  items,
  imageHeight,
  tallImages,
  columns,
  bgColor,
  borderRadius,
  isDragging,
  imagePadding,
  enableDetailModal,
}) => {
  return (
    <Grid templateColumns={`repeat(${columns ?? 5}, 1fr)`} gap={2} width="100%">
      {items.map((item, index) => (
        <ListGridItem
          rank={index + 1}
          item={item}
          key={index}
          imageHeight={
            !!imageHeight
              ? imageHeight
              : tallImages
              ? { base: "5.5em", sm: "6.5em" }
              : { base: "3.9em", sm: "5em" }
          }
          bgColor={bgColor}
          borderRadius={borderRadius}
          isDragging={isDragging}
          imagePadding={imagePadding}
          enableDetailModal={enableDetailModal}
        />
      ))}
    </Grid>
  );
};

export default ListGrid;
