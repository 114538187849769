import {
  Box,
  Circle,
  Fade,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { motion } from "framer-motion";
import React from "react";
import { FaBars } from "react-icons/fa6";
import { ListItem as ListItemType } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import { ActionButtonProps } from "../../Buttons";
import ItemModal from "./ItemModal";

export function animateLayoutChanges(args: any) {
  const { isSorting, wasDragging } = args;

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

const ItemImage: React.FC<{
  width: string;
  short?: boolean;
  enableMotion?: boolean;
  item: ListItemType;
  imagePadding: string | number;
}> = ({ width, short, enableMotion, item, imagePadding }) => {
  const Inner = () => {
    return (
      <Image
        src={item.imageURI}
        alt={item.title}
        minW={width}
        w={width}
        height={short ? "3em" : "5em"}
        objectFit="cover"
        borderRadius={
          localStorage.getItem("borderRadiusDisabled") === "true"
            ? undefined
            : "4px"
        }
        padding={imagePadding}
      />
    );
  };

  if (enableMotion) {
    return (
      <motion.div
        layoutId={`image-${item._id ?? item.title}${short ?? ""}`}
        transition={{ duration: 0.3 }}
      >
        <Inner />
      </motion.div>
    );
  } else {
    return <Inner />;
  }
};

interface ListItemProps {
  rank?: number;
  item: ListItemType;
  isEditing?: boolean;
  editAction?: ActionButtonProps;
  allowDrag: boolean;
  imageWidth: number; // in pixels
  linkEnabled?: boolean;
  short?: boolean;
  theme?: UserTheme;
  imagePadding?: string | number;
  enableDetailModal?: boolean;
  cursor?: string;
  enableMotion?: boolean;
  onClick?: () => void;
  [key: string]: any;
}

const ListItem: React.FC<ListItemProps> = ({
  rank,
  item,
  isEditing,
  allowDrag,
  editAction,
  imageWidth,
  short,
  theme,
  imagePadding,
  enableDetailModal,
  cursor,
  enableMotion,
  onClick,
  ...rest
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      // animateLayoutChanges,
      id: item._id ?? item.title + item.imageURI,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "auto",
  };

  const modalDisclosure = useDisclosure();

  const width = (short ? imageWidth * 0.6 : imageWidth) + "px";
  return (
    <HStack
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="center"
      mb="1em"
      onClick={onClick}
      {...rest}
    >
      {rank && (
        <Circle
          bg={theme?.textColor ?? "topFive.black"}
          color="white"
          size={12}
          alignItems="center"
          justifyContent="center"
          mr={-8}
          zIndex="0"
        >
          <Text
            ml={-5}
            fontWeight="bold"
            fontSize="md"
            color={theme?.name === "Black" ? "black" : theme?.cardBg}
          >
            {rank}
          </Text>
        </Circle>
      )}
      <Box
        h="100%"
        w="100%"
        onClick={enableDetailModal ? modalDisclosure.onOpen : () => {}}
        cursor={cursor ?? enableDetailModal ? "pointer" : "auto"}
        color={isEditing ? "topFive.black" : theme?.textColor}
      >
        <HStack height="100%" position="relative" justify="space-between">
          <HStack spacing={short ? "0.75em" : "1em"}>
            <ItemImage
              width={width}
              short={short}
              enableMotion={enableMotion}
              item={item}
              imagePadding={imagePadding ?? "0"}
            />
            <VStack spacing="1" justify="center" align="start" textAlign="left">
              <Heading
                fontSize={
                  item.subtitle
                    ? short
                      ? "14px"
                      : "16px"
                    : short
                    ? "16px"
                    : "18px"
                }
                color={isEditing ? "topFive.black" : theme?.textColor}
              >
                {item.title}
              </Heading>

              {item.subtitle && (
                <Heading
                  fontSize={short ? "0.75em" : "0.9em"}
                  fontWeight={500}
                  color={isEditing ? "topFive.black" : theme?.textColor}
                  opacity={0.8}
                  textAlign="left"
                >
                  {item.subtitle}
                </Heading>
              )}
            </VStack>
          </HStack>
          <Fade in={isEditing} unmountOnExit>
            {editAction && (
              <VStack spacing={3} style={{ touchAction: "none" }}>
                <Icon
                  as={editAction.icon}
                  fontSize={short ? "xl" : "2xl"}
                  onClick={editAction.action}
                  cursor="pointer"
                />
                {allowDrag && (
                  <Icon
                    cursor="grab"
                    as={FaBars}
                    fontSize="2xl"
                    {...listeners}
                    {...attributes}
                  />
                )}
              </VStack>
            )}
          </Fade>
        </HStack>
        <ItemModal item={item} disclosure={modalDisclosure} />
      </Box>
    </HStack>
  );
};

export default ListItem;
